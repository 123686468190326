import React from "react"
import { navigate, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { getValidationSchemaLogin } from "../components/validations"
import { Formik, Form, Field, ErrorMessage } from "formik"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import InputLabel from "@material-ui/core/InputLabel"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import CircularProgress from '@material-ui/core/CircularProgress';
import AlertDialog from '../components/dialog'
import { register as registerAction, finishRegister } from '../state/store'
import { useSelector, useDispatch } from 'react-redux'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      logoImageTrade: file(relativePath: { eq: "cedar-logo-trademark.png" }) {
        childImageSharp {
          fixed(width: 120) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return <Img fixed={data.logoImageTrade.childImageSharp.fixed} />
}

const RegisterForm = ({ loginResult, submitting }) => {
  return (
    <Formik
      validationSchema={getValidationSchemaLogin}
      initialValues={{
        email: "",
        password: "",
      }}
      onSubmit={(values, actions) => {
        loginResult(values)
        actions.setSubmitting(false)
      }}
    >
      {({
        errors,
        isSubmitting,
      }) => (
        <Form>
          <Field
            type="email"
            name="email"
            render={({ field, form }) => (
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel htmlFor="email">Email</InputLabel>
                <OutlinedInput
                  id="email"
                  {...field}
                  error={
                    form.errors[field.name] && form.touched[field.name]
                      ? true
                      : false
                  }
                  autoComplete="email"
                  // autoFocus
                  labelWidth={50}
                  required
                />
                <ErrorMessage name="email">
                  {msg => <FormHelperText>{msg}</FormHelperText>}
                </ErrorMessage>
              </FormControl>
            )}
          />
          <Field
            type="password"
            name="password"
            render={({ field, form }) => (
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel htmlFor="password">Password</InputLabel>
                <OutlinedInput
                  type="password"
                  id="password"
                  {...field}
                  error={
                    form.errors[field.name] && form.touched[field.name]
                      ? true
                      : false
                  }
                  labelWidth={80}
                  required
                />
                <ErrorMessage name="password">
                  {msg => <FormHelperText>{msg}</FormHelperText>}
                </ErrorMessage>
              </FormControl>
            )}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={
              isSubmitting || Object.keys(errors).length > 0 || submitting
            }
            type="submit"
          >
            Register Account
          </Button>
        </Form>
      )}
    </Formik>
  )
}

const Register = () => {
  const {loggedIn, register}  = useSelector(state=>state.db)
  const dispatch = useDispatch()
  const classes = useStyles()
  return (
    <Layout>
      <SEO title="Register" />
    <Container component="main" maxWidth="xs">
      {register && register.error && <AlertDialog title="Error" message={register.error.msg} closeDialog={()=>dispatch(finishRegister({loading: false, msg: null, error: null}))}/>}
      {register && register.loading && <CircularProgress/>}
      {!loggedIn && 
      <div className={classes.paper}>
        <Image />
        <Typography component="h1" variant="h5">
          Register
        </Typography>
        <Typography component="p" variant="body1">
          Please register with your email and password. Once registered you can login to complete an application to become an agent. 
        </Typography>
        <RegisterForm loginResult={({email, password})=>dispatch(registerAction(email, password))} submitting={register && register.loading}/>
      </div>}
      {loggedIn && <div className={classes.paper}>
        <Image />
        <Typography component="h1" variant="h5">
          Thanks for registering, head to the home page.
        </Typography>
        <Button style={{marginTop: '1rem'}} variant="contained" color="primary" onClick={()=>navigate('/')}>Home</Button>
      </div>}
    </Container>
    </Layout>
  )
}

export default Register
